import { Carousel, Slide } from 'vue-carousel';
import StarRating from 'vue-star-rating';
import { routeToProduct } from "../../../../helpers";

export default {
  name: 'HomeFeedbacks',
  components: {
    Carousel,
    Slide,
    StarRating
  },
  props: {
    feedbacks: {
      type: Array,
      required: true,
      validator: function(value) {
        return value.every(function(item) {
          return typeof item.id !== 'undefined' &&
                 typeof item.name === 'string' &&
                 typeof item.message === 'string' &&
                 typeof item.rating !== 'undefined';
        });
      }
    }
  },

  data() {
    return {
      windowSize: window.innerWidth,
      localFeedbacks: [],
      responsiveItems: 2,
      perPageSettings: [
        [100, 1],
        [200, 2],
        [780, 3],
        [1150, 4]
      ]
    };
  },

  computed: {
    isDesktopView: function() {
      return this.windowSize > 1024;
    },
    responsiveFeedbacks: function() {
      return this.localFeedbacks.slice(0, this.responsiveItems);
    },
    shouldShowNavigation: function() {
      return this.localFeedbacks.length > 4;
    },
    hasMoreFeedbacks: function() {
      return this.responsiveItems < this.localFeedbacks.length;
    }
  },

  watch: {
    feedbacks: {
      handler: function(newItems) {
        this.localFeedbacks = newItems.slice();
      },
      deep: true
    }
  },

  created() {
    this.localFeedbacks = this.feedbacks.slice();
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    routeToProduct: routeToProduct,
    loadMoreFeedbacks() {
      this.responsiveItems += 2;
    },
    handleResize() {
      this.windowSize = window.innerWidth;
    },
    navigateCarousel(direction) {
      const carousel = this.$refs.carousel;
      if (carousel) {
        carousel.advancePage(direction);
      }
    }
  }
};
